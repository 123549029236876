<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <div class="page-title">{{ $route.meta.title }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="formData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="formItemTotal"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading"
          @options-update="onTableChange"
          :actionViewRouteLink="'studentTimetableDetail'"
        >
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>
 
 <script>
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'Timetable',
  components: {
    Datatable,
  },
  data() {
    return {
      tableLoading: false,
      tableHeaders: [
        { text: '課程編號', value: 'course_code' },
        { text: '課程標題', value: 'course_name' },
        { text: '課程上限人數', value: 'max_student' },
        { text: '已報名人數', value: 'apply_count' },
        {
          text: '',
          value: 'actionView',
          sortable: false,
          align: 'end',
        },
      ],
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 20,
      tablePageLimitOptions: [20, 50, 100],
      formItemTotal: 0,
      formData: [],
    }
  },
  methods: {
    async getTimetableCourse() {
      this.tableLoading = true

      try {
        const payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
          verify_token: this.getAdminToken(),
          course_mode: 'offline',
          get_apply_count: true,
          status:'active'
        }

        const result = await this.$XHR.api('cms_get_course', payload)
        this.$func.log('-----Get Timetable Course-----')
        this.$func.log(result)

        this.formItemTotal = result.total

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          const data = {
            id: resultData.id,
            course_code: resultData.course_code,
            course_name: resultData.course_name,
            apply_count: resultData.apply_count,
            max_student: resultData.max_student,
          }

          temp.push(data)
        }

        this.formData = temp
      } catch (error) {
        this.$func.log('-----Get Timetable Course fail-----')
        this.$func.log(error)

        let msg = ''
        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }

        this.formData = []
        this.formItemTotal = 0
      } finally {
        this.tableLoading = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.getTimetableCourse()
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.getTimetableCourse()
    }
  },
}
</script>
 